var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    {
      attrs: {
        "grid-width": "full",
        pageTitle: "Voucher Codes for Voucher Rule " + _vm.voucherRuleId,
      },
    },
    [
      _c(
        "div",
        { staticClass: "vx-col w-full mb-base" },
        [
          _c(
            "vx-card",
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-start" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-4 sm:mb-0 mb-2",
                      on: { click: _vm.addVoucherCode },
                    },
                    [_vm._v("Add")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-4 sm:mb-0 mb-2",
                      attrs: { type: "border" },
                      on: { click: _vm.backToVoucherRule },
                    },
                    [_vm._v("Back to Voucher Rule")]
                  ),
                ],
                1
              ),
              _c("ag-grid-vue", {
                ref: "agGridTable",
                staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                attrs: {
                  components: _vm.cellRendererComponents,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  rowData: _vm.voucherCodes,
                  gridOptions: _vm.gridOptions,
                  autoGroupColumnDef: _vm.autoGroupColumnDef,
                  rowSelection: "single",
                  pagination: true,
                  context: _vm.context,
                  suppressPaginationPanel: false,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }